import React, {useEffect, useMemo, useState,} from 'react'
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import Icon from 'components/atoms/Icon/Icon';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {handleRevealContactByID, InterfacePixelLeadData} from 'store/slices/insights';
import Loading from 'components/atoms/Loading/Loading';
import {showDate} from 'utils/workWithData';
import PixelRow from './PulseRow';
import InputDebounce from 'components/atoms/InputDebounce/InputDebounce';
import EntryFormatter from 'utils/EntryFormatter';
import Button from 'components/atoms/Button/Button';
import {toast} from "react-toastify";
import LeadHelper from "../../../helpers/LeadHelper";
import {messages} from "../../../utils/message";
import constants from "../../../utils/constants";
import {useTableSelect} from "../../../utils/hooks/useTableSelect";
import SelectAllCheckbox from "../../molecules/SelectAllCheckbox/SelectAllCheckbox";
import Popup from "../../molecules/Popup/Popup";
import ExportModal from "../SearchMain/ExportModal";
import {EnrichmentContactRevealSourceENUM} from "../../../utils/enums/enrichment";
import {hmsToSeconds} from '../../../utils/hmsToSeconds'
import { useNavigate, useSearchParams } from 'react-router-dom';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'none',
    size?: string,
}}
const isMobile = window.innerWidth <= 810
const PulseBody: React.FC<{
  searchPeople: string | number, setSearchPeople: React.Dispatch<React.SetStateAction<string | number>>,
  searchLinkedin: any,
  filterRevealed: {label: string, value: boolean} | null,
  filterJobTitle: any[],
  filterCompany: any[],
  filterIndustry: any[],
  filterLocationPerson: any[],
  filterLocationCompany: any[],
  filterGender: any[],
  filterSeniority: any[],
  filterDepartment: any[],
  filterCompanyHeadcount: any[],
  filterIncomeRange: any[],
  filterCompanyRevenue: any[],
  pageCurrent: number
  setPageCurrent: React.Dispatch<React.SetStateAction<number>>,
  query: string
}> = ({
  searchPeople, setSearchPeople,
  searchLinkedin,
  filterRevealed,
  filterLocationPerson, filterLocationCompany,
  filterIncomeRange, filterGender, filterSeniority, filterDepartment, filterJobTitle, filterIndustry,
  filterCompany, filterCompanyHeadcount, filterCompanyRevenue, pageCurrent, setPageCurrent, query
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: leads, isLoading, count } = useAppSelector((state) => state.insights.leads);
  const [searchParams, setSearchParams] = useSearchParams()

  const {unselectedRows, setUnselectedRows, selectedRows, setSelectedRows, isModalExportOpen,
    setIsModalExportOpen, isAllListSelected, setIsAllListSelected, setAnchorExportSelectMenu,
    isExportSelectMenuOpen, anchorExportSelectMenu, resetExport, isAllRowsInPageSelected,
    handleSelectPeoplePage, handleSelectAllPeople, isSelectAllChecked} = useTableSelect({leads: leads.map(el => ({...el, leadId: el?.id})), count: leads?.length || 0}) // Length will be replaced with count when pagination is done
    
  const [isSearching, setIsSearching] = useState(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  
    useEffect(() => {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.set("current_page", String(pageCurrent));
        return newParams;
      });
    }, [pageCurrent]);
 
    const [expanded, setExpanded] = useState<ExpandedState>({});
  const data = useMemo(() => leads, [leads])

  const columns = React.useMemo<ColumnDef<InterfacePixelLeadData>[]>(() =>
    isMobile ? [
      { accessorKey: "leadName",
      header: () => "Lead",
      meta: { size: "auto" },
      cell: ({ row, getValue }) => {
        const value: any = getValue();
        let dataSecondary: string | null = null;
        // if (row.original.email) dataSecondary = row.original.email;
        return <div className="flex items-center gap-3"><div className="text-left inline-grid">
          <div className="text-base truncate text-white-500">{value}</div>
          {dataSecondary && <div className="text-sm text-gray-400 shadow mt-1 truncate">{dataSecondary}</div>}
        </div></div>
    }}]
  : [
    { accessorKey: "leadScore", header: "Score", meta: { size: "15%" }, enableColumnFilter: false, sortingFn: (rowA, rowB, columnId) => {
      return Number(rowA.original.score) - Number(rowB.original.score);
    }  },
    { accessorKey: "leadName", header: () => "Lead", meta: { size: "auto" },
      cell: ({ row, getValue }) => {
        const value: any = getValue();
        let dataSecondary: string | null = null;
        // if (row.original.email) dataSecondary = row.original.email;
        return <div className="flex items-center gap-3"><div className="text-left inline-grid">
          <div className="text-base truncate text-white-500">{value}</div>
          {dataSecondary && <div className="text-sm text-gray-400 shadow mt-1 truncate">{dataSecondary}</div>}
        </div></div>
      }
    },
    {   accessorKey: "orgName", header: () => "Company", meta: { size: "15%" },
        cell: ({ row, getValue, }) => {
          const value: any = getValue();
          return <div className="text-base truncate">{value}</div>
    }},
    {   accessorKey: "visits", header: "Visits", meta: { filterVariant: 'none', size: '8%' }, sortingFn: (rowA, rowB, columnId) => {
      return Number(rowA.original.visits) - Number(rowB.original.visits);
    } },
    {   accessorKey: "pageViews", header: () => "Page Views", meta: { filterVariant: 'none', size: '9%' }, sortingFn: (rowA, rowB, columnId) => {
      return Number(rowA.original.pageViews) - Number(rowB.original.pageViews);
    } },
    {   accessorKey: "duration", header: "Duration", meta: { filterVariant: 'none', size: '8%' }, sortingFn: (rowA, rowB, columnId) => {      
      return hmsToSeconds(rowA.original.duration) - hmsToSeconds(rowB.original.duration);
    }},
    {   accessorKey: "visitLast", header: () => "Last seen",
        cell: ({ row, getValue }) => {
          const value = getValue() as string;
          return <div className='relative flex items-center justify-center text-sm'>{showDate(value)}</div>
        },
        meta: { filterVariant: 'none', size: '13%' },
    },
    { accessorKey: "isContactRevealed",
      header: () => null,
      meta: { size: "13%" },
      cell: ({ row, getValue }) => {
        const isContactRevealed: any = getValue();
        if (!isContactRevealed) {
          return <div className="flex justify-center">
            <Button
              iconName="credits" iconSize={15} size="xs"
              onClick={(e: any) => {
                e.stopPropagation();
                dispatch(handleRevealContactByID({ leadId: row.original.id })).unwrap().then((data) => {
                  const CRData = data?.data
                  if (LeadHelper.hasContactData(CRData)) toast.success(messages.ENRICHMENT_CONTACTREVEAL_SUCCESS, {toastId: "contactRevealed"});
                  else toast.error(messages.ENRICHMENT_CONTACTREVEAL_CONTACT_MISSING);
                }).catch((error) => { toast.error(error?.message) })
              }}
            >Reveal Contact</Button>
          </div>
        }
      },
    },
  ], [])
  
  const table = useReactTable({
    data: useMemo(() => leads, [leads]), columns, filterFns: {},
    state: { columnFilters, expanded },
    initialState: { sorting: [{ id: "visitLast", desc: true }], pagination: { pageIndex: pageCurrent - 1, pageSize: 25 } },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    paginateExpandedRows: false,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  })

  return <>
    <div className='w-full flex py-4 px-3 borderBottom gap-3 justify-end items-center'>
      {/* <InputDebounce onChange={value => setSearchPeople(value) } value={searchPeople} placeholder='Search People' width='w-48' sizeInput='tiny' /> */}
      <div className='text-sm text-gray-400 pr-3'>
        {/*{isSearching*/}
        {/*  ? <Loading height="auto" spinnerSize={24} />*/}
        {/*  : <><b className='text-white-500'>{EntryFormatter.formatNumber(leads.length)}</b> results found</>*/}
        {/*}*/}
        <div className="flex items-center text-sm gap-5">
          {isSearching || isLoading
              ? <Loading height="auto" spinnerSize={38} />
              : <>
                {isAllListSelected || selectedRows?.length > 0 ? (
                  <div className="flex items-center gap-5">
                    <p className="text-gray-400">
                      <b className="text-white-500">
                        {isAllListSelected
                            ? Number(
                                (leads.length > constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK
                                    ? constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK
                                    : leads.length) - unselectedRows.length
                            ).toLocaleString()
                            : selectedRows.length}
                      </b>{" "}
                      selected
                    </p>
                    <Tooltip content="Export selected leads to CSV">
                      <Button iconName="download" size="xs" className="float-right" onClick={() => setIsModalExportOpen(true)}>Export</Button>
                    </Tooltip>
                  </div>
                    )
                    : <p className="text-gray-400 sm:block hidden"><b className="text-white-500 mr-1">{EntryFormatter.formatNumber(leads.length)}</b> results found</p>
                }
                <Button onClick={() => navigate("/exports")} iconName="document" size="xs">Export Lists</Button>
              </>}
        </div>
    </div></div>
    {isLoading ? <Loading/> : <><table>
      <thead>
      {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isLeadOrVisit = ["leadName", "visitLast"].includes(header.id);
              const isAlignedLeft = ["leadName", "orgName"].includes(header.id);
              const canSort = header.column.getCanSort();
              const canFilter = header.column.getCanFilter();
              const sortedState = header.column.getIsSorted();
              const columnName = header.column.columnDef.header;
              return (
                  <th key={header.id} style={{width: header.column.columnDef.meta?.size || "auto", textAlign: isAlignedLeft ? "left" : "center"}}>
                    {!isLeadOrVisit ? (
                        columnName === "Score" ? (
                            <SelectAllCheckbox
                                count={leads.length}
                                setAnchorExportSelectMenu={setAnchorExportSelectMenu}
                                isSelectAllChecked={isSelectAllChecked}
                                isAllListSelected={isAllListSelected}
                                resetExport={resetExport}
                                isAllRowsInPageSelected={isAllRowsInPageSelected}
                                handleSelectPeoplePage={handleSelectPeoplePage}
                                handleSelectAllPeople={handleSelectAllPeople}
                                isExportSelectMenuOpen={isExportSelectMenuOpen}
                                anchorExportSelectMenu={anchorExportSelectMenu}
                                header={header}
                            />
                        ) : <div className='flex flex-row items-center gap-2'>
                        {canSort && (
                            <div className="group cursor-pointer select-none w-max flex" onClick={header.column.getToggleSortingHandler()} style={{margin: isAlignedLeft ? "0" : "0 auto"}}>
                              <div className="flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition">
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: <Tooltip content="Sort Ascending"><Icon name='sort-up' size={16} /></Tooltip>,
                                  desc: <Tooltip content="Sort Descending"><Icon name='sort-down' size={16} /></Tooltip>
                                }[sortedState as string] ?? null}
                              </div>
                            </div>
                        )}
                      </div>
                    ) : (
                        <div className='flex flex-row items-center gap-2'>
                          {canSort && (
                              <div className="group cursor-pointer select-none w-max flex" onClick={header.column.getToggleSortingHandler()} style={{margin: isAlignedLeft ? "0" : "0 auto"}}>
                                <div className="flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition">
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  {{
                                    asc: <Tooltip content="Sort Ascending"><Icon name='sort-up' size={16} /></Tooltip>,
                                    desc: <Tooltip content="Sort Descending"><Icon name='sort-down' size={16} /></Tooltip>
                                  }[sortedState as string] ?? null}
                                </div>
                              </div>
                          )}
                          {canFilter && <Filter column={header.column} />}
                        </div>
                    )}
                  </th>
              );
            })}
          </tr>
      ))}
      </thead>
    </table>

    <div className='h-full overflow-y-auto'><table className={`main${table.getRowCount() === 0 || isSearching ? ' h-full':''}`}><tbody>
      {isSearching
        ? <tr className='noHover'><td><div className="flex flex-col gap-4 items-center justify-center h-full">
          <Loading height="auto" />
          <h1 className="text-sm text-gray-400">Searching...</h1>
        </div></td></tr>
        : <>
          {table.getRowCount() === 0
            // ⭐ Empty State
            ? <tr className='noHover'><td><div className="flex gap-3 items-center justify-center h-full">
                <Icon name="search" size={28} />
                <h1 className="text-base">No matching results were found</h1>
              </div></td></tr>
            : table.getRowModel().rows.map(row =>
                  <PixelRow  isSelected={isAllListSelected ? !unselectedRows.includes(row.original.id) : selectedRows.includes(row.original.id)}
              setSelectedRows={isAllListSelected ? setUnselectedRows : setSelectedRows} key={row.id} row={row} />)
          }
      </>}
    </tbody></table></div> </>}
    <table>
      <tfoot><tr><td><div className='flex items-center justify-between'>
        <div className="flex items-center gap-1">
          Showing{" "}
          <strong className="font-bold text-white-500">
            {table.getRowCount() > 0 
            ? <>
                {EntryFormatter.formatNumber((pageCurrent - 1) * 25 + 1)}
                -
                {EntryFormatter.formatNumber(pageCurrent * 25 > count ? count : pageCurrent * 25)}
              </>
            : 0}
          </strong>
          {"of "}
          <strong className="font-bold text-white-500">
            {EntryFormatter.formatNumber(count || table.getRowCount())}
          </strong>
        </div>
        <div className='flex sm:flex-row gap-4 sm:gap-6 justify-end'>
          <div className="pagination">
            <div className={`${ pageCurrent === 1 ? `disabled` : ``}`} onClick={()=>setPageCurrent(1)}><Icon name="angle-left-double" size={16} /></div>
            <div className={`${ pageCurrent === 1 ? `disabled` : ``}`} onClick={()=>setPageCurrent(pageCurrent - 1)}><Icon name="angle-left" size={16} /></div>
            <div className={`${ pageCurrent === Math.ceil(count / 25) ? `disabled` : ``}`} onClick={()=> setPageCurrent(pageCurrent + 1)}><Icon name="angle-right" size={16} /></div>
            <div className={`${ pageCurrent === Math.ceil(count / 25) ? `disabled` : ``}`} onClick={()=>setPageCurrent(Math.ceil(count / 25))}><Icon name="angle-right-double" size={16} /></div>
          </div>
        </div>
      </div></td></tr></tfoot>
    </table>
    {isModalExportOpen && (
        <Popup onClose={() => setIsModalExportOpen(false)} type="modal">
          <ExportModal source={EnrichmentContactRevealSourceENUM.PIXEL} totalRowsInList={leads.length} isAllListSelected={isAllListSelected}
             setIsAllListSelected={setIsAllListSelected} unselectedRows={unselectedRows} selectedRows={selectedRows}
             setIsModalOpen={setIsModalExportOpen} setSelectedRows={setSelectedRows} query={query} />
        </Popup>
    )}
  </>
}
export default PulseBody
function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}
  if (filterVariant === 'none') return <div className="h-5" />
  return filterVariant === 'range' ? (
    <div className="flex space-x-2">
      {/* See faceted column filters example for min max values functionality */}
      <InputDebounce
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={value =>
          column.setFilterValue((old: [number, number]) => [value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <InputDebounce
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={value =>
          column.setFilterValue((old: [number, number]) => [old?.[0], value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : filterVariant === 'select' ? (
    <select onChange={e => column.setFilterValue(e.target.value)} value={columnFilterValue?.toString()}>
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  )
  : <InputDebounce sizeInput="tiny" width="auto" placeholder='Search' onChange={value => column.setFilterValue(value)} value={(columnFilterValue ?? '') as string} />
}
