import React, { useEffect } from "react";
import styles from "./style.module.scss";
import Button from "../../components/atoms/Button/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetPassword, resetResetPassword } from "store/slices/auth.slice";
import Loading from "components/atoms/Loading/Loading";
import { toast } from "react-toastify";
import theme from "../../assets/themes"
import { InputField } from "components";


const ResetPasswordPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();
  const location = useLocation()
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading, isError } = useAppSelector((state) => state.auth.resetPassword);
  const save = (data: { email: string }) => {
    dispatch(resetPassword(data.email));
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.analytics.page("Forgot Password")
    const container = document.getElementById("bg-container");
    if (container) {
      container.style.backgroundImage = `url(${theme["bgImage"]})`;
    }
  }, [])
  
  useEffect(() => {
    if (isSuccess) {
      toast.success("Password Reset! We sent an email to your inbox with your new password.");
      dispatch(resetResetPassword());
      navigate("/signin");
    }
    if (isError) {
      toast.error(isError);
      dispatch(resetResetPassword());
    }
  }, [isSuccess, isError]);
  return (
    <div className={styles.main} id="bg-container">
      <div className={styles.main_container}>
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              top: "0",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <img
              src={theme["logo"]}
              alt="logo"
              className={styles.main_container_logo}
              onClick={() => navigate("/signin")}
            />
            <h1 className="text-2xl font-bold">{isLoading ? "" : "Reset Password"}</h1>
            <form
              onSubmit={handleSubmit(save)}
              className={styles.main_container_form}
            >
              <InputField
                type="text"
                autoFocus
                name="email"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
                    message: "Invalid email address",
                  },
                }}
                placeholder="Enter your email"
              />
              <Button isProcessing={isLoading} type="secondary">Reset password</Button>
            </form>
            <Link to="/signin" className="link text-sm">Go Back</Link>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
