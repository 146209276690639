import Button from "components/atoms/Button/Button";
import Icon from "components/atoms/Icon/Icon";
import InputField from "components/atoms/InputField/InputField";
import InputSelect from "components/atoms/InputSelect/InputSelect";
import Container from "components/atoms/layout/Container/Container";
import isArray from "lodash/isArray";
import range from "lodash/range";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  createClientICP,
  getClientICP, resetCreateClientICP, resetUpdateClientICP,
  updateClientICP,
} from "store/slices/icp.slice";
import {
  ClientICPFilterOperatorENUM, GROUPED_OPTIONS_COMPANY,
  GROUPED_OPTIONS_JOBTITLE,
  OPTIONS_COMPANY,
  OPTIONS_COMPANY_HEADCOUNT,
  OPTIONS_DEPARTMENT,
  OPTIONS_GENDER,
  OPTIONS_INDUSTRY,
  OPTIONS_JOBTITLE,
  OPTIONS_LOCATION,
  OPTIONS_SENIORITY,
} from "../SearchMain/SearchMainHelper";
import { toast } from "react-toastify";
import Loading from "components/atoms/Loading/Loading";
import { setCurrentPage } from "store/slices/ui.slice";
import EntryFormatter from "../../../utils/EntryFormatter";


interface Filter {
  name: string;
  keyword: string;
  values: (string | any)[] | null;
}
const filters: Filter[] = [
  { name: "Person Location", keyword: 'person_location', values: OPTIONS_LOCATION },
  { name: "Linkedin", keyword: 'linkedin', values: null },
  { name: "Job Title", keyword: 'job_title', values: GROUPED_OPTIONS_JOBTITLE },
  // NOTE: The following data are not yet stored in the database
  // { name: "Seniority", values: OPTIONS_SENIORITY },
  // { name: "Department", values: OPTIONS_DEPARTMENT },
  // { name: "Company Headcount", values: OPTIONS_COMPANY_HEADCOUNT },
  { name: "Industry", keyword: 'industry', values: OPTIONS_INDUSTRY },
  { name: "Company Name", keyword: 'organization_name', values: GROUPED_OPTIONS_COMPANY },
  { name: "Company Location", keyword: 'organization_location', values: OPTIONS_LOCATION },
];
const LeadScoringModule: FC = () => {
  const dispatch = useAppDispatch();
  const { data: ICPData, isLoading: ICPIsLoading } = useAppSelector((state) => state.icp);
  const { isLoading: ICPCreateIsLoading, isSuccess: isFilterCreated } = useAppSelector((state) => state.icp.createClientICP);
  const { isLoading: ICPUpdateIsLoading, isSuccess: isFilterUpdated} = useAppSelector((state) => state.icp.updateClientICP);

  const [filterRows, setFilterRows] = useState<
    { name: string;
      value:  {label:string, value: string | number}[];
      weight: number;
      options: any[] | null | undefined;
    }[]
  >([ { name: "", value: [], weight: 0, options: [] } ]);
  const [threshold, setThreshold] = useState(0);
  const handleFilterChange = (index: number, name: any) => {
    const selectedFilter = filters.find((filter) => filter.name === name?.value);
    const updatedRows = [...filterRows];
    updatedRows[index] = {
      name, value: [], weight: 0,
      options: selectedFilter?.values,
    }; // Reset value and weight when filter changes
    setFilterRows(updatedRows);
  };
  const handleValueChange = (index: number, value: {label:string, value: string | number}[] | string) => {
    const updatedRows = [...filterRows];
    if (typeof value !== "string" && value?.length > 1) {
      const isValueHasIsKnownOption = value?.some(el => el.value === ClientICPFilterOperatorENUM.IS_REQUIRED)
      const updatedValue = isValueHasIsKnownOption
          ? (
              // Check if "IS_REQUIRED" is first
              value[0].value === ClientICPFilterOperatorENUM.IS_REQUIRED
                  ? value.filter(el => el.value !== ClientICPFilterOperatorENUM.IS_REQUIRED) // Remove "IS_REQUIRED" if first
                  : [{label: 'Is Known', value: ClientICPFilterOperatorENUM.IS_REQUIRED}] // Set only "IS_REQUIRED" if not first
          )
          : value; // If no "IS_REQUIRED", keep the value as it is
      updatedRows[index].value = updatedValue;
    } else {
      updatedRows[index].value = value as any;
    }
    setFilterRows(updatedRows);
  };
  const handleWeightChange = (index: number, weight: number) => {
    const updatedRows = [...filterRows];
    updatedRows[index].weight = weight;
    setFilterRows(updatedRows);
  };
  const addFilterRow = () => {
    setFilterRows([
      ...filterRows, { name: "", value: [], weight: 0, options: [] },
    ]); // Add a new empty row
  };
  const removeFilterRow = (index: number) => {
    const newFilters = filterRows.filter((_, i) => i !== index);
    setFilterRows(newFilters); // Remove the row at the specified index
  };
  const save = () => {
    if (threshold === 0) {
      toast.error("Please set a threshold score before saving any filters.", { toastId: "lead-scoring-threshold" });
      return;
    }
    const dataToSubmit = {
      filters: filterRows.map((filter: any) => ({
        name: filter.name.value,
        value: isArray(filter.value)
          ? filter.value.map((v: any) => v.value)
          : filter.value,
        weight: filter.weight,
      })),
      threshold,
    };
    if (!ICPData) {
      // If no records exist, create a new one
      dispatch(createClientICP(dataToSubmit));
    } else {
      // If records exist, update the existing one
      dispatch(updateClientICP({ ...dataToSubmit }));
    }
    toast.success("Lead scoring filters saved!", { toastId: "lead-scoring-saved" });
  };
  useEffect(() => {
    dispatch(getClientICP());
  }, [dispatch]);
  useEffect(() => {
    if (filterRows.length === 0) addFilterRow();
  }, [filterRows]);
  useEffect(() => {
    if (!ICPData) return;
    // Populate filterRows and threshold with existing data
    const existingFilters = ICPData.icpFilters || [];
    const populatedFilterRows = existingFilters.map((filter: any) => ({
      name: filter?.name ? { label: filter.name, value: filter.name } : null,
      value: isArray(filter.value)
        ? filter.value.map((fv: any) => ({ label: EntryFormatter.replaceLabelByValue(fv), value: fv }))
        : filter.value,
      weight: filter.weight,
      options: filters.find((f) => f.name === filter.name)?.values || null,
    }));

    setFilterRows(populatedFilterRows);
    setThreshold(ICPData.threshold);
  }, [ICPData]);
    useEffect(() => {
    window.analytics.page("Lead Scoring")
    dispatch(setCurrentPage("scoring"));
  }, []);

  

  useEffect(() => {
    if(isFilterCreated || isFilterUpdated) {
    dispatch(resetCreateClientICP())
    dispatch(resetUpdateClientICP())
      window.analytics.track("Lead Scoring Set", {
        icpFilters: ICPData?.icpFilters,
        filterId: ICPData?.id,
        threshold: ICPData?.threshold
      })
    }
  },[isFilterCreated, isFilterUpdated])
  if (ICPIsLoading) return <Loading />
  return <Container>
      <div className="flex sm:gap-6"><div className="items-center flex gap-3 text-white-500">
        <Icon name="score" size={30} />
        <h1 className="font-bold capitalize text-2xl">Lead Scoring</h1>
      </div></div>
      <div className="grid grid-cols-1 py-5 gap-6 bg-gray-900 rounded-lg">
        <div className="grid grid-rows gap-4 justify-start w-fit px-5">
          <div className="flex flex-col gap-1">
            <h3 className="text-xl w-min font-bold">Threshold</h3>
            <p className="text-gray-400 text-base">Minimum score at which a lead is enriched and sent to CRM (0-100).</p>
          </div>
          <div className="flex gap-2 items-center">
            <label className="text-white-500 font-bold">Score</label>
            <InputField
              type="number" name="threshold" value={threshold} size="small" width="w-52" max={100} className="font-bold" iconName="score"
              onChange={(ev: any) => {
                const value = Number(ev.target.value);
                if (value >= 0 && value <= 100) { setThreshold(value); }
              }} />
        </div></div>
        <table className="table style-1">
          <thead><tr>
            <th className="w-1/4"><div className="flex gap-2 justify-center items-center">
                <Icon name="filter" size={20} color="var(--color-white-500)" />
                <div className="text-white-500 font-bold">Filter</div>
            </div></th>
            <th className="w-1/4"><div className="flex justify-center items-center gap-2">
                <Icon name="document" size={20} color="var(--color-white-500)" />
                <div className="text-white-500 font-bold">Value</div>
            </div></th>
            <th><div className="flex justify-center items-center gap-2">
                <Icon name="score" size={20} color="var(--color-white-500)" />
                <div className="text-white-500 font-bold">Score</div>
            </div></th>
            <th className="w-1/5"></th>
          </tr></thead>
          <tbody>
            {filterRows.map((filterRow, index) => (<tr key={index}>
              <td>
                <InputSelect
                  options={filters.map((filter) => ({label: filter.name, value: filter.name,})) as []}
                  state={filterRow.name as any} variant="light"
                  setState={(name: string) => handleFilterChange(index, name)}
                  placeholder="Select Filter" isMulti={false}
                />
              </td>
              <td>
                {(filterRows[index]?.options && (
                  <InputSelect
                    options={(filterRows[index]?.options as []) || []}
                    state={filterRows[index]?.value as any}
                    setState={(value: any) => handleValueChange(index, value)}
                    placeholder="Select Value" isMulti variant="light"
                  />
                )) || (
                  <InputField size="small" value={filterRows[index]?.value ?? ""} onChange={(e: any) => handleValueChange(index, e.target.value)} />
                )}
              </td>
              <td><div className="flex justify-center gap-2">
                {range(1, 11).map((weight: number) => (
                  <Button
                    size="xs" key={weight}
                    onClick={() => handleWeightChange(index, weight)}
                    className={`w-9 !h-9 !border !border-solid !font-bold !text-[14px] ${filterRows[index]?.weight < weight ? "!bg-paper-400 !border-gray-700" : "!border-primary-500"}`}
                    disabled={!filterRows[index].name || filterRows[index].name.length === 0}
                  >
                    {weight}
                  </Button>
                ))}
              </div></td>
              <td><div className="flex justify-end">
                <Button size="xs" iconName="trash" iconSize={16} className={`!border !border-solid !font-bold !text-[14px] !bg-paper-400 !border-gray-700`} onClick={() => removeFilterRow(index)}>Remove</Button>
              </div></td>
            </tr>))}
          </tbody>
        </table>
        <div className="flex justify-end gap-4 px-5">
          <Button size="sm" onClick={addFilterRow} iconName="filter" iconSize={16} type="secondary">New Filter</Button>
          <Button size="sm" onClick={save} iconName="save"
            isProcessing={ICPCreateIsLoading || ICPUpdateIsLoading} isProcessingTitle="Saving Filters..."
          >Save Filters</Button>
        </div>
      </div>
  </Container>
};
export default LeadScoringModule;
